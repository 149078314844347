// JobListings.js

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Header from '../components/header';
import Footer from '../components/footer';
import Container from '../components/container';
import '../styles/tailwind.scss';
import '../styles/careers.scss';


const JobListings = () => {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { location: { ne: null } } }
    ) {
      nodes {
        frontmatter {
          title
          location
          type
          link
          button_text
          category
        }
        html
      }
    }
  }
`);

  const jobListings = data.allMarkdownRemark.nodes;

  return (
    <Container>
      <Header />
      <div className='careers-page'>
        <h1 className='fs-primary-heading'>Careers</h1>
        <div className='careers'>

          <div className="careers ">
            {jobListings.map((listing, index) => {
              const { frontmatter: job } = listing;


              return <div class="relative max-w-1.5 flex min-h-screen flex-col jus items-start justify-center overflow-hidden p-6 sm:py-12 text-left">
                <div class="bg-white  shadow-xl shadow-gray-100 w-full max-w-4xl flex flex-col sm:flex-row gap-3 sm:items-center  justify-between px-5 py-4 rounded-md">
                  <div>
                    <span class="text-blue-800 text-sm">{job.type}</span>
                    <h3 class="font-bold mt-px">{job.title}</h3>
                    <div class="flex items-center gap-3 mt-2">
                      <span class="bg-blue-100 text-blue-700 rounded-full px-3 py-1 text-sm">{job.category}</span>
                      <span class="text-slate-600 text-sm flex gap-1 items-center"> <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>{job.location}</span>
                    </div>
                  </div>
                  <a href={job.link} target='_blank'>
                    <button class="bg-blue-900 text-white font-medium px-4 py-2 rounded-md flex gap-1 items-center">{job.button_text}<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                    </button>
                  </a>
                </div>
              </div>
            }
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default JobListings;